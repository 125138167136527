import { useEffect, useState } from 'react';
import SavedDesignsCard from '../card/SavedDesignsCard';
import DeleteDesignModal from '../modals/DeleteDesignModal';
import SavedDesigns from 'services/SavedDesignsService';

const MyProjectsList = ({ projects, checkSavedDesignLimit }) => {
  const [list, setList] = useState(projects);
  const [show, setShow] = useState(false);
  const [projectid, setProjectid] = useState(0);

  const deleteDesign = async (e, id) => {
    try {
      e.preventDefault();
      await SavedDesigns.deleteSavedDesign(id);
      setShow(false);
      setList((projects) => projects.filter((x) => x.id !== id));
    } catch (e) {
      console.error(e);
    }
  };

  const closePopUp = async () => {
    setProjectid(0);
    setShow(false);
  };

  const showPopUp = async (id) => {
    setProjectid(id);
    setShow(true);
  };

  const handleCopy = (id) => {
    window.open(`/project/copy/${id}`);
  };

  useEffect(() => {
    if (checkSavedDesignLimit) checkSavedDesignLimit(list);
  }, [list, checkSavedDesignLimit]);

  return (
    <>
      <div className="myprojectslistouter">
        {list &&
          list.map((project) => (
            <SavedDesignsCard
              key={project.id}
              project={project}
              showPopUp={showPopUp}
              handleCopy={handleCopy}
            />
          ))}
      </div>
      <DeleteDesignModal
        show={show}
        deleteDesign={deleteDesign}
        closePopUp={closePopUp}
        projectid={projectid}
      />
    </>
  );
};

export default MyProjectsList;
