import { Modal } from 'react-bootstrap';

function DeleteDesignModal({ show, closePopUp, deleteDesign, projectid }) {
  return (
    <>
      {show && (
        <Modal
          centered
          show={show}
          onHide={closePopUp}
          className="auth-popup deletedesign-popup"
        >
          <Modal.Body>
            <h4>Are you sure you want to delete?</h4>
            <div className="buttons-container">
              <button className="button-outlined" onClick={() => closePopUp()}>
                <span>No</span>
              </button>
              <button
                className="button-contained"
                onClick={(e) => {
                  deleteDesign(e, projectid);
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default DeleteDesignModal;
