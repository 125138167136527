import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';

function SideLink({ link, icon, hoverIcon, linkText }) {
  const router = useRouter();
  return (
    <>
      <li className={router.pathname === link ? 'active' : ''}>
        <Link href={link}>
          <a>
            <Image alt="HomeIcon" src={icon} />
            <span>
              <ReactSVG src={hoverIcon.src} />
            </span>
            {linkText}
          </a>
        </Link>
      </li>
    </>
  );
}

export default SideLink;
