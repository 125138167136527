import Link from 'next/link';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import myprojectlistaction from '@/images/myprojectlist-action.svg';
import copyIcon from '@/images/copy-icon.svg';
import deleteIcon from '@/images/delete-icon.svg';

const SavedDesignsCard = ({ project, showPopUp, handleCopy }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [show, setShow] = useState(false);

  const cardRef = useRef(null);

  useEffect(() => {
    if (!project.thumbnail) setImageLoading(false);
  }, []);

  const generateThumbnail = (project) => {
    const timeStamp = new Date(project.date_updated ?? null);
    const thumbString = `${project.thumbnail}?date_updated=${timeStamp}`;
    return thumbString;
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleClickOutside = (e) => {
    setShow(false);
  };

  useEffect(() => {
    if (show) document.addEventListener('mousedown', handleClickOutside);
    return () => {
      if (show) document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  return (
    <>
      <div
        className="myprojectlist-single"
        onContextMenu={handleContextMenu}
        ref={cardRef}
      >
        <div className={`myprojectlist-single-actions ${show ? 'show' : ''}`}>
          <button className="btn">
            <Image alt="myprojectlistaction" src={myprojectlistaction} />
          </button>
          <ul className={`${show ? 'show' : ''}`}>
            <li>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleCopy(project.id);
                }}
              >
                <Image alt="copyIcon" src={copyIcon} /> Copy
              </button>
            </li>
            <li>
              <button
                className="btn btn-primary"
                onClick={() => {
                  showPopUp(project.id);
                }}
              >
                <Image alt="deleteIcon" src={deleteIcon} /> Delete
              </button>
            </li>
          </ul>
        </div>
        <Link href={`/project/${project.id}`} key={project.id}>
          <a>
            <div className={'myprojectlist-single-img'}>
              {project.thumbnail && (
                <Image
                  onLoadingComplete={() => setImageLoading(false)}
                  src={generateThumbnail(project)}
                  alt={project.design_name}
                  layout="fill"
                  loading="lazy"
                />
              )}
              {imageLoading && (
                <div className="spinner-container">
                  <Spinner colorScheme="blue" />
                </div>
              )}
            </div>
            <h4>{project.design_name}</h4>
            {project.product == null ? (
              ''
            ) : (
              <>
                {project.product.product_type == null ? (
                  ''
                ) : (
                  <span>{project.product.product_type.name}</span>
                )}
              </>
            )}
          </a>
        </Link>
      </div>
    </>
  );
};

export default SavedDesignsCard;
