import { useSession } from 'next-auth/react';
import Sidebar from '.';

function SidebarContainer({ children }) {
  const { data: session } = useSession();

  return (
    <>
      <div className="pgouterblock">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {session && <Sidebar session={session} pagename="my-designs" />}
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SidebarContainer;
