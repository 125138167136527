import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Image from 'next/image';

import HomeIcon from '@/images/home-icon.svg';
import DesignsIcon from '@/images/designs-icon.svg';
import OrdersIcon from '@/images/orders-icon.svg';
import SettingsIcon from '@/images/settings-icon.svg';
import NewDesign from '@/images/new-design.png';
import NewDesignHover from '@/images/new-design-hover.png';
import AutofillIcon from '@/images/autofill-icon.svg';

import HomeIconHover from '@/images/home-icon-hover.svg';
import DesignsIconHover from '@/images/designs-icon-hover.svg';
import OrdersIconHover from '@/images/orders-icon-hover.svg';
import AutofillIconHover from '@/images/autofill-icon-hover.svg';
import SettingsIconHover from '@/images/settings-icon-hover.svg';

import useRole from '@/components/hooks/useRole';
import SideLink from './SideLink';
import Products from 'services/ProductsService';
import { useSiteInfo } from '@/context/SiteInfoContext';

export default function Sidebar({ session }) {
  const { role } = useRole();
  const { siteInfo } = useSiteInfo();

  const createProject = async () => {
    try {
      const savedID = await Products.createNewProduct(
        session.user.id,
        siteInfo.id,
      );
      window.open(`/project/${savedID.create_saveddesigns_item.id}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error('Can Not Create New design. Please Try Again.');
    }
  };

  return (
    <div className="pgsidebar">
      <ul className="sidebarmenu" id="sidebarmenu">
        <SideLink
          linkText={'Home'}
          link={'/'}
          icon={HomeIcon}
          hoverIcon={HomeIconHover}
        />
        {(role === 'uploader' || role === 'designer') && (
          <li>
            <Button variant="primary" onClick={() => createProject()}>
              <Image alt="NewDesign" src={NewDesign} />
              <Image alt="NewDesignHover" src={NewDesignHover} /> Create New
              Design
            </Button>
          </li>
        )}
        <SideLink
          link={'/my-designs'}
          linkText={'My Designs'}
          icon={DesignsIcon}
          hoverIcon={DesignsIconHover}
        />
        <SideLink
          link={'/orders'}
          linkText={'Orders'}
          icon={OrdersIcon}
          hoverIcon={OrdersIconHover}
        />
        <SideLink
          link={'/autofill'}
          linkText={'Autofill'}
          icon={AutofillIcon}
          hoverIcon={AutofillIconHover}
        />
        <SideLink
          link={'/profile'}
          linkText={'Settings'}
          icon={SettingsIcon}
          hoverIcon={SettingsIconHover}
        />
      </ul>
    </div>
  );
}
